import React, { useEffect, useState } from 'react'

const defaultState = {
  access: false,
  setAccess: () => {},
}

const AccessContext = React.createContext(defaultState)

const AccessProvider = ({ children }) => {

  const [state, setState] = useState({})

  const setAccess = value => {
    localStorage.setItem('access', JSON.stringify(value))
    setState({ access: value })
  }

  useEffect(() => {
    const hasAccess = JSON.parse(localStorage.getItem('access'))
    if (hasAccess) {
      setState({ access: hasAccess })
    }
  }, [])

  return (
    <AccessContext.Provider
      value={{
        access: state.access,
        setAccess,
      }}
    >
      {children}
    </AccessContext.Provider>
  )
}

export default AccessContext
export { AccessProvider }