// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-to-curated-js": () => import("./../../../src/pages/addToCurated.js" /* webpackChunkName: "component---src-pages-add-to-curated-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-network-js": () => import("./../../../src/pages/network.js" /* webpackChunkName: "component---src-pages-network-js" */),
  "component---src-pages-project-all-publications-js": () => import("./../../../src/pages/projectAllPublications.js" /* webpackChunkName: "component---src-pages-project-all-publications-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-projects-users-js": () => import("./../../../src/pages/projects_users.js" /* webpackChunkName: "component---src-pages-projects-users-js" */),
  "component---src-pages-stock-js": () => import("./../../../src/pages/stock.js" /* webpackChunkName: "component---src-pages-stock-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-weather-report-js": () => import("./../../../src/pages/weatherReport.js" /* webpackChunkName: "component---src-pages-weather-report-js" */),
  "component---src-templates-digest-js": () => import("./../../../src/templates/digest.js" /* webpackChunkName: "component---src-templates-digest-js" */),
  "component---src-templates-member-js": () => import("./../../../src/templates/member.js" /* webpackChunkName: "component---src-templates-member-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-project-pubs-js": () => import("./../../../src/templates/projectPubs.js" /* webpackChunkName: "component---src-templates-project-pubs-js" */),
  "component---src-templates-stock-js": () => import("./../../../src/templates/stock.js" /* webpackChunkName: "component---src-templates-stock-js" */)
}

